export const dictionaryCompiled = [
  ["beyond", "boney", "ebony", "bone", "one", "no", "on"],
  ["bridge", "ridge", "dirge", "ride", "die", "ie", "i"],
  ["bureau", "bare", "bear", "ear", "era", "are", "re"],
  ["center", "centre", "recent", "enter", "rent", "net", "ten"],
  ["centre", "center", "recent", "enter", "rent", "net", "ten"],
  ["desert", "steer", "ester", "reset", "terse", "rest", "set"],
  ["driven", "drive", "diver", "ride", "die", "ie", "i"],
  ["driver", "drive", "diver", "ride", "die", "ie", "i"],
  ["either", "there", "three", "ether", "here", "her", "he"],
  ["ensure", "nurse", "sure", "user", "sue", "use", "us"],
  ["entire", "inert", "inter", "tire", "tie", "ie", "i"],
  ["export", "trope", "poet", "opt", "pot", "top", "to"],
  ["failed", "field", "file", "life", "lie", "ie", "i"],
  ["health", "heath", "hate", "heat", "eat", "tea", "at"],
  ["intend", "diet", "edit", "tide", "die", "ie", "i"],
  ["invest", "stein", "site", "its", "sit", "it", "i"],
  ["itself", "filet", "file", "life", "lie", "ie", "i"],
  ["leaves", "slave", "salve", "sale", "seal", "sea", "as"],
  ["losing", "sling", "sign", "sing", "sin", "in", "i"],
  ["nature", "earn", "near", "ear", "era", "are", "re"],
  ["normal", "roman", "manor", "norm", "nor", "no", "on"],
  ["option", "point", "pinto", "into", "not", "no", "on"],
  ["partly", "party", "part", "trap", "art", "rat", "at"],
  ["period", "pride", "pried", "ride", "die", "ie", "i"],
  ["person", "poser", "prose", "spore", "rope", "per", "re"],
  ["random", "roman", "manor", "norm", "nor", "no", "on"],
  ["recent", "center", "centre", "enter", "rent", "net", "ten"],
  ["region", "ignore", "groin", "grin", "ring", "in", "i"],
  ["report", "trope", "poet", "opt", "pot", "top", "to"],
  ["safety", "feast", "east", "seat", "eat", "tea", "at"],
  ["sample", "ample", "maple", "lamp", "palm", "map", "am"],
  ["secret", "steer", "ester", "reset", "terse", "rest", "set"],
  ["seeing", "singe", "sign", "sing", "sin", "in", "i"],
  ["signal", "sling", "sign", "sing", "sin", "in", "i"],
  ["silver", "liver", "evil", "live", "lie", "ie", "i"],
  ["single", "singe", "sign", "sing", "sin", "in", "i"],
  ["sister", "resist", "site", "its", "sit", "it", "i"],
  ["smooth", "shoot", "sooth", "host", "shot", "hot", "oh"],
  ["sought", "shout", "south", "host", "shot", "hot", "oh"],
  ["spirit", "strip", "spit", "its", "sit", "it", "i"],
  ["steady", "stead", "east", "seat", "eat", "tea", "at"],
  ["street", "steer", "ester", "reset", "terse", "rest", "set"],
  ["strike", "skirt", "stir", "its", "sit", "it", "i"],
  ["string", "sting", "sign", "sing", "sin", "in", "i"],
  ["summer", "serum", "sure", "user", "sue", "use", "us"],
  ["talent", "latte", "late", "tale", "eat", "tea", "at"],
  ["tennis", "stein", "site", "its", "sit", "it", "i"],
  ["thrown", "north", "thorn", "horn", "nor", "no", "on"],
  ["tissue", "suite", "site", "its", "sit", "it", "i"],
  ["walker", "wreak", "wear", "ear", "era", "are", "re"],
  ["wealth", "wheat", "hate", "heat", "eat", "tea", "at"],
  ["winter", "inert", "inter", "tire", "tie", "ie", "i"],
  ["wonder", "drown", "down", "now", "own", "no", "on"],
  ["abroad", "aboard", "board", "broad", "road", "rod", "do"],
  ["advise", "aside", "aide", "idea", "die", "ie", "i"],
  ["appear", "paper", "rape", "ear", "era", "are", "re"],
  ["artist", "stair", "stir", "its", "sit", "it", "i"],
  ["aspect", "caste", "east", "seat", "eat", "tea", "at"],
  ["butter", "brute", "rebut", "tuber", "tube", "bet", "be"],
  ["casino", "scion", "sonic", "coin", "icon", "in", "i"],
  ["closet", "stole", "lost", "lots", "slot", "lot", "to"],
  ["cousin", "scion", "sonic", "coin", "icon", "in", "i"],
  ["cruise", "curse", "sure", "user", "sue", "use", "us"],
  ["denial", "ideal", "aide", "idea", "die", "ie", "i"],
  ["derive", "drive", "diver", "ride", "die", "ie", "i"],
  ["donate", "atone", "note", "tone", "not", "no", "on"],
  ["garage", "gear", "rage", "ear", "era", "are", "re"],
  ["ignore", "region", "groin", "grin", "ring", "in", "i"],
  ["insect", "stein", "site", "its", "sit", "it", "i"],
  ["mentor", "tenor", "note", "tone", "not", "no", "on"],
  ["others", "those", "ethos", "host", "shot", "hot", "oh"],
  ["poetry", "trope", "poet", "opt", "pot", "top", "to"],
  ["priest", "spite", "site", "its", "sit", "it", "i"],
  ["refuse", "reuse", "sure", "user", "sue", "use", "us"],
  ["resist", "sister", "site", "its", "sit", "it", "i"],
  ["resume", "reuse", "sure", "user", "sue", "use", "us"],
  ["script", "strip", "spit", "its", "sit", "it", "i"],
  ["senate", "tease", "east", "seat", "eat", "tea", "at"],
  ["shower", "sower", "swore", "worse", "rose", "ore", "or"],
  ["stance", "caste", "east", "seat", "eat", "tea", "at"],
  ["wooden", "endow", "down", "now", "own", "no", "on"],
  ["aboard", "abroad", "board", "broad", "road", "rod", "do"],
  ["camera", "cream", "care", "race", "ear", "era", "are"],
  ["cancer", "crane", "care", "race", "ear", "era", "are"],
  ["career", "racer", "care", "race", "ear", "era", "are"],
  ["charge", "grace", "care", "race", "ear", "era", "are"],
  ["course", "source", "curse", "sure", "user", "sue", "use"],
  ["design", "signed", "singe", "sign", "sing", "sin", "in"],
  ["detail", "dealt", "delta", "late", "tale", "eat", "tea"],
  ["estate", "state", "taste", "east", "seat", "eat", "tea"],
  ["honest", "stone", "onset", "note", "tone", "not", "no"],
  ["listen", "silent", "islet", "list", "its", "sit", "it"],
  ["manner", "ramen", "earn", "near", "ear", "era", "are"],
  ["market", "taker", "rate", "tear", "ear", "era", "are"],
  ["matter", "treat", "rate", "tear", "ear", "era", "are"],
  ["mature", "tamer", "mate", "meat", "team", "eat", "tea"],
  ["mental", "metal", "mate", "meat", "team", "eat", "tea"],
  ["nearby", "yearn", "earn", "near", "ear", "era", "are"],
  ["nights", "night", "thing", "hint", "thin", "hit", "hi"],
  ["parent", "taper", "rate", "tear", "ear", "era", "are"],
  ["please", "asleep", "easel", "lease", "sale", "seal", "sea"],
  ["pursue", "purse", "super", "sure", "user", "sue", "use"],
  ["reader", "dare", "dear", "read", "ear", "era", "are"],
  ["recall", "clear", "care", "race", "ear", "era", "are"],
  ["repair", "parer", "rare", "rear", "ear", "era", "are"],
  ["repeat", "eater", "rate", "tear", "ear", "era", "are"],
  ["rescue", "secure", "curse", "sure", "user", "sue", "use"],
  ["retain", "irate", "rate", "tear", "ear", "era", "are"],
  ["search", "reach", "care", "race", "ear", "era", "are"],
  ["secure", "rescue", "curse", "sure", "user", "sue", "use"],
  ["signed", "design", "singe", "sign", "sing", "sin", "in"],
  ["silent", "listen", "islet", "list", "its", "sit", "it"],
  ["source", "course", "curse", "sure", "user", "sue", "use"],
  ["stolen", "stone", "onset", "note", "tone", "not", "no"],
  ["strain", "saint", "satin", "stain", "its", "sit", "it"],
  ["treaty", "treat", "rate", "tear", "ear", "era", "are"],
  ["arrest", "stare", "rate", "tear", "ear", "era", "are"],
  ["asleep", "please", "easel", "lease", "sale", "seal", "sea"],
  ["assert", "stare", "rate", "tear", "ear", "era", "are"],
  ["battle", "table", "bleat", "late", "tale", "eat", "tea"],
  ["breast", "beast", "baste", "east", "seat", "eat", "tea"],
  ["cattle", "cleat", "eclat", "late", "tale", "eat", "tea"],
  ["depict", "edict", "diet", "edit", "tide", "die", "ie"],
  ["ethics", "heist", "shit", "this", "its", "sit", "it"],
  ["farmer", "frame", "fare", "fear", "ear", "era", "are"],
  ["faster", "after", "fare", "fear", "ear", "era", "are"],
  ["genius", "suing", "using", "sign", "sing", "sin", "in"],
  ["gifted", "fetid", "diet", "edit", "tide", "die", "ie"],
  ["inmate", "meant", "mate", "meat", "team", "eat", "tea"],
  ["locate", "cleat", "eclat", "late", "tale", "eat", "tea"],
  ["prayer", "parer", "rare", "rear", "ear", "era", "are"],
  ["scream", "cream", "care", "race", "ear", "era", "are"],
  ["soften", "stone", "onset", "note", "tone", "not", "no"],
  ["statue", "state", "taste", "east", "seat", "eat", "tea"],
  ["tackle", "cleat", "eclat", "late", "tale", "eat", "tea"],
  ["editor", "tired", "tried", "diet", "edit", "tide", "die"],
  ["german", "anger", "range", "earn", "near", "ear", "era"],
  ["lawyer", "early", "layer", "relay", "real", "ear", "era"],
  ["lights", "slight", "sight", "shit", "this", "its", "sit"],
  ["marine", "remain", "ramen", "earn", "near", "ear", "era"],
  ["master", "stream", "stare", "rate", "tear", "ear", "era"],
  ["nearly", "early", "layer", "relay", "real", "ear", "era"],
  ["orange", "anger", "range", "earn", "near", "ear", "era"],
  ["permit", "merit", "remit", "timer", "item", "time", "tie"],
  ["phrase", "spare", "parse", "spear", "rape", "ear", "era"],
  ["raised", "aider", "dare", "dear", "read", "ear", "era"],
  ["rarely", "early", "layer", "relay", "real", "ear", "era"],
  ["really", "early", "layer", "relay", "real", "ear", "era"],
  ["reason", "saner", "snare", "earn", "near", "ear", "era"],
  ["regard", "grade", "dare", "dear", "read", "ear", "era"],
  ["relief", "rifle", "filer", "flier", "file", "life", "lie"],
  ["remain", "marine", "ramen", "earn", "near", "ear", "era"],
  ["reward", "drawer", "dare", "dear", "read", "ear", "era"],
  ["senior", "resin", "rinse", "risen", "siren", "rise", "sir"],
  ["slight", "lights", "sight", "shit", "this", "its", "sit"],
  ["spread", "spare", "parse", "spear", "rape", "ear", "era"],
  ["stream", "master", "stare", "rate", "tear", "ear", "era"],
  ["target", "great", "grate", "gear", "rage", "ear", "era"],
  ["varied", "aider", "dare", "dear", "read", "ear", "era"],
  ["answer", "saner", "snare", "earn", "near", "ear", "era"],
  ["barely", "early", "layer", "relay", "real", "ear", "era"],
  ["barrel", "baler", "blare", "bare", "bear", "ear", "era"],
  ["basket", "stake", "steak", "skate", "east", "seat", "eat"],
  ["dancer", "cedar", "dare", "dear", "read", "ear", "era"],
  ["drawer", "reward", "dare", "dear", "read", "ear", "era"],
  ["filter", "rifle", "filer", "flier", "file", "life", "lie"],
  ["insert", "resin", "rinse", "risen", "siren", "rise", "sir"],
  ["ladder", "dread", "dare", "dear", "read", "ear", "era"],
  ["marble", "baler", "blare", "bare", "bear", "ear", "era"],
  ["marker", "remark", "rearm", "rare", "rear", "ear", "era"],
  ["parade", "drape", "dare", "dear", "read", "ear", "era"],
  ["praise", "spare", "parse", "spear", "rape", "ear", "era"],
  ["preach", "caper", "recap", "care", "race", "ear", "era"],
  ["regain", "anger", "range", "earn", "near", "ear", "era"],
  ["remark", "marker", "rearm", "rare", "rear", "ear", "era"],
  ["shared", "heard", "dare", "dear", "read", "ear", "era"],
  ["streak", "stake", "steak", "skate", "east", "seat", "eat"],
  ["timber", "merit", "remit", "timer", "item", "time", "tie"],
  ["verbal", "baler", "blare", "bare", "bear", "ear", "era"],
  ["breath", "earth", "heart", "hater", "rate", "tear", "ear"],
  ["castle", "least", "steal", "slate", "stale", "east", "seat"],
  ["credit", "direct", "tired", "tried", "diet", "edit", "tide"],
  ["danger", "garden", "anger", "range", "earn", "near", "ear"],
  ["direct", "credit", "tired", "tried", "diet", "edit", "tide"],
  ["father", "earth", "heart", "hater", "rate", "tear", "ear"],
  ["garden", "danger", "anger", "range", "earn", "near", "ear"],
  ["gather", "earth", "heart", "hater", "rate", "tear", "ear"],
  ["latest", "least", "steal", "slate", "stale", "east", "seat"],
  ["latter", "alter", "later", "alert", "rate", "tear", "ear"],
  ["marked", "armed", "dream", "dare", "dear", "read", "ear"],
  ["planet", "plate", "leapt", "petal", "pleat", "late", "tale"],
  ["player", "replay", "early", "layer", "relay", "real", "ear"],
  ["rather", "earth", "heart", "hater", "rate", "tear", "ear"],
  ["relate", "alter", "later", "alert", "rate", "tear", "ear"],
  ["replay", "player", "early", "layer", "relay", "real", "ear"],
  ["retail", "alter", "later", "alert", "rate", "tear", "ear"],
  ["stable", "least", "steal", "slate", "stale", "east", "seat"],
  ["threat", "earth", "heart", "hater", "rate", "tear", "ear"],
  ["travel", "alter", "later", "alert", "rate", "tear", "ear"],
  ["admire", "armed", "dream", "dare", "dear", "read", "ear"],
  ["banker", "brake", "break", "baker", "bare", "bear", "ear"],
  ["depart", "trade", "tread", "dare", "dear", "read", "ear"],
  ["pastor", "sport", "post", "spot", "stop", "opts", "opt"],
  ["poster", "sport", "post", "spot", "stop", "opts", "opt"],
  ["rental", "alter", "later", "alert", "rate", "tear", "ear"],
  ["resign", "singer", "resin", "rinse", "risen", "siren", "rise"],
  ["sacred", "scared", "cedar", "dare", "dear", "read", "ear"],
  ["scared", "sacred", "cedar", "dare", "dear", "read", "ear"],
  ["singer", "resign", "resin", "rinse", "risen", "siren", "rise"],
  ["thread", "earth", "heart", "hater", "rate", "tear", "ear"],
  ["create", "react", "trace", "cater", "crate", "care", "race"],
  ["carpet", "react", "trace", "cater", "crate", "care", "race"],
	// DUPLICATES BELOW!
	["beyond", "boney", "ebony", "bone", "one", "no", "on"],
  ["bridge", "ridge", "dirge", "ride", "die", "ie", "i"],
  ["bureau", "bare", "bear", "ear", "era", "are", "re"],
  ["center", "centre", "recent", "enter", "rent", "net", "ten"],
  ["centre", "center", "recent", "enter", "rent", "net", "ten"],
  ["desert", "steer", "ester", "reset", "terse", "rest", "set"],
  ["driven", "drive", "diver", "ride", "die", "ie", "i"],
  ["driver", "drive", "diver", "ride", "die", "ie", "i"],
  ["either", "there", "three", "ether", "here", "her", "he"],
  ["ensure", "nurse", "sure", "user", "sue", "use", "us"],
  ["entire", "inert", "inter", "tire", "tie", "ie", "i"],
  ["export", "trope", "poet", "opt", "pot", "top", "to"],
  ["failed", "field", "file", "life", "lie", "ie", "i"],
  ["health", "heath", "hate", "heat", "eat", "tea", "at"],
  ["intend", "diet", "edit", "tide", "die", "ie", "i"],
  ["invest", "stein", "site", "its", "sit", "it", "i"],
  ["itself", "filet", "file", "life", "lie", "ie", "i"],
  ["leaves", "slave", "salve", "sale", "seal", "sea", "as"],
  ["losing", "sling", "sign", "sing", "sin", "in", "i"],
  ["nature", "earn", "near", "ear", "era", "are", "re"],
  ["normal", "roman", "manor", "norm", "nor", "no", "on"],
  ["option", "point", "pinto", "into", "not", "no", "on"],
  ["partly", "party", "part", "trap", "art", "rat", "at"],
  ["period", "pride", "pried", "ride", "die", "ie", "i"],
  ["person", "poser", "prose", "spore", "rope", "per", "re"],
  ["random", "roman", "manor", "norm", "nor", "no", "on"],
  ["recent", "center", "centre", "enter", "rent", "net", "ten"],
  ["region", "ignore", "groin", "grin", "ring", "in", "i"],
  ["report", "trope", "poet", "opt", "pot", "top", "to"],
  ["safety", "feast", "east", "seat", "eat", "tea", "at"],
  ["sample", "ample", "maple", "lamp", "palm", "map", "am"],
  ["secret", "steer", "ester", "reset", "terse", "rest", "set"],
  ["seeing", "singe", "sign", "sing", "sin", "in", "i"],
  ["signal", "sling", "sign", "sing", "sin", "in", "i"],
  ["silver", "liver", "evil", "live", "lie", "ie", "i"],
  ["single", "singe", "sign", "sing", "sin", "in", "i"],
  ["sister", "resist", "site", "its", "sit", "it", "i"],
  ["smooth", "shoot", "sooth", "host", "shot", "hot", "oh"],
  ["sought", "shout", "south", "host", "shot", "hot", "oh"],
  ["spirit", "strip", "spit", "its", "sit", "it", "i"],
  ["steady", "stead", "east", "seat", "eat", "tea", "at"],
  ["street", "steer", "ester", "reset", "terse", "rest", "set"],
  ["strike", "skirt", "stir", "its", "sit", "it", "i"],
  ["string", "sting", "sign", "sing", "sin", "in", "i"],
  ["summer", "serum", "sure", "user", "sue", "use", "us"],
  ["talent", "latte", "late", "tale", "eat", "tea", "at"],
  ["tennis", "stein", "site", "its", "sit", "it", "i"],
  ["thrown", "north", "thorn", "horn", "nor", "no", "on"],
  ["tissue", "suite", "site", "its", "sit", "it", "i"],
  ["walker", "wreak", "wear", "ear", "era", "are", "re"],
  ["wealth", "wheat", "hate", "heat", "eat", "tea", "at"],
  ["winter", "inert", "inter", "tire", "tie", "ie", "i"],
  ["wonder", "drown", "down", "now", "own", "no", "on"],
  ["abroad", "aboard", "board", "broad", "road", "rod", "do"],
  ["advise", "aside", "aide", "idea", "die", "ie", "i"],
  ["appear", "paper", "rape", "ear", "era", "are", "re"],
  ["artist", "stair", "stir", "its", "sit", "it", "i"],
  ["aspect", "caste", "east", "seat", "eat", "tea", "at"],
  ["butter", "brute", "rebut", "tuber", "tube", "bet", "be"],
  ["casino", "scion", "sonic", "coin", "icon", "in", "i"],
  ["closet", "stole", "lost", "lots", "slot", "lot", "to"],
  ["cousin", "scion", "sonic", "coin", "icon", "in", "i"],
  ["cruise", "curse", "sure", "user", "sue", "use", "us"],
  ["denial", "ideal", "aide", "idea", "die", "ie", "i"],
  ["derive", "drive", "diver", "ride", "die", "ie", "i"],
  ["donate", "atone", "note", "tone", "not", "no", "on"],
  ["garage", "gear", "rage", "ear", "era", "are", "re"],
  ["ignore", "region", "groin", "grin", "ring", "in", "i"],
  ["insect", "stein", "site", "its", "sit", "it", "i"],
  ["mentor", "tenor", "note", "tone", "not", "no", "on"],
  ["others", "those", "ethos", "host", "shot", "hot", "oh"],
  ["poetry", "trope", "poet", "opt", "pot", "top", "to"],
  ["priest", "spite", "site", "its", "sit", "it", "i"],
  ["refuse", "reuse", "sure", "user", "sue", "use", "us"],
  ["resist", "sister", "site", "its", "sit", "it", "i"],
  ["resume", "reuse", "sure", "user", "sue", "use", "us"],
  ["script", "strip", "spit", "its", "sit", "it", "i"],
  ["senate", "tease", "east", "seat", "eat", "tea", "at"],
  ["shower", "sower", "swore", "worse", "rose", "ore", "or"],
  ["stance", "caste", "east", "seat", "eat", "tea", "at"],
  ["wooden", "endow", "down", "now", "own", "no", "on"],
  ["aboard", "abroad", "board", "broad", "road", "rod", "do"],
  ["camera", "cream", "care", "race", "ear", "era", "are"],
  ["cancer", "crane", "care", "race", "ear", "era", "are"],
  ["career", "racer", "care", "race", "ear", "era", "are"],
  ["charge", "grace", "care", "race", "ear", "era", "are"],
  ["course", "source", "curse", "sure", "user", "sue", "use"],
  ["design", "signed", "singe", "sign", "sing", "sin", "in"],
  ["detail", "dealt", "delta", "late", "tale", "eat", "tea"],
  ["estate", "state", "taste", "east", "seat", "eat", "tea"],
  ["honest", "stone", "onset", "note", "tone", "not", "no"],
  ["listen", "silent", "islet", "list", "its", "sit", "it"],
  ["manner", "ramen", "earn", "near", "ear", "era", "are"],
  ["market", "taker", "rate", "tear", "ear", "era", "are"],
  ["matter", "treat", "rate", "tear", "ear", "era", "are"],
  ["mature", "tamer", "mate", "meat", "team", "eat", "tea"],
  ["mental", "metal", "mate", "meat", "team", "eat", "tea"],
  ["nearby", "yearn", "earn", "near", "ear", "era", "are"],
  ["nights", "night", "thing", "hint", "thin", "hit", "hi"],
  ["parent", "taper", "rate", "tear", "ear", "era", "are"],
  ["please", "asleep", "easel", "lease", "sale", "seal", "sea"],
  ["pursue", "purse", "super", "sure", "user", "sue", "use"],
  ["reader", "dare", "dear", "read", "ear", "era", "are"],
  ["recall", "clear", "care", "race", "ear", "era", "are"],
  ["repair", "parer", "rare", "rear", "ear", "era", "are"],
  ["repeat", "eater", "rate", "tear", "ear", "era", "are"],
  ["rescue", "secure", "curse", "sure", "user", "sue", "use"],
  ["retain", "irate", "rate", "tear", "ear", "era", "are"],
  ["search", "reach", "care", "race", "ear", "era", "are"],
  ["secure", "rescue", "curse", "sure", "user", "sue", "use"],
  ["signed", "design", "singe", "sign", "sing", "sin", "in"],
  ["silent", "listen", "islet", "list", "its", "sit", "it"],
  ["source", "course", "curse", "sure", "user", "sue", "use"],
  ["stolen", "stone", "onset", "note", "tone", "not", "no"],
  ["strain", "saint", "satin", "stain", "its", "sit", "it"],
  ["treaty", "treat", "rate", "tear", "ear", "era", "are"],
  ["arrest", "stare", "rate", "tear", "ear", "era", "are"],
  ["asleep", "please", "easel", "lease", "sale", "seal", "sea"],
  ["assert", "stare", "rate", "tear", "ear", "era", "are"],
  ["battle", "table", "bleat", "late", "tale", "eat", "tea"],
  ["breast", "beast", "baste", "east", "seat", "eat", "tea"],
  ["cattle", "cleat", "eclat", "late", "tale", "eat", "tea"],
  ["depict", "edict", "diet", "edit", "tide", "die", "ie"],
  ["ethics", "heist", "shit", "this", "its", "sit", "it"],
  ["farmer", "frame", "fare", "fear", "ear", "era", "are"],
  ["faster", "after", "fare", "fear", "ear", "era", "are"],
  ["genius", "suing", "using", "sign", "sing", "sin", "in"],
  ["gifted", "fetid", "diet", "edit", "tide", "die", "ie"],
  ["inmate", "meant", "mate", "meat", "team", "eat", "tea"],
  ["locate", "cleat", "eclat", "late", "tale", "eat", "tea"],
  ["prayer", "parer", "rare", "rear", "ear", "era", "are"],
  ["scream", "cream", "care", "race", "ear", "era", "are"],
  ["soften", "stone", "onset", "note", "tone", "not", "no"],
  ["statue", "state", "taste", "east", "seat", "eat", "tea"],
  ["tackle", "cleat", "eclat", "late", "tale", "eat", "tea"],
  ["editor", "tired", "tried", "diet", "edit", "tide", "die"],
  ["german", "anger", "range", "earn", "near", "ear", "era"],
  ["lawyer", "early", "layer", "relay", "real", "ear", "era"],
  ["lights", "slight", "sight", "shit", "this", "its", "sit"],
  ["marine", "remain", "ramen", "earn", "near", "ear", "era"],
  ["master", "stream", "stare", "rate", "tear", "ear", "era"],
  ["nearly", "early", "layer", "relay", "real", "ear", "era"],
  ["orange", "anger", "range", "earn", "near", "ear", "era"],
  ["permit", "merit", "remit", "timer", "item", "time", "tie"],
  ["phrase", "spare", "parse", "spear", "rape", "ear", "era"],
  ["raised", "aider", "dare", "dear", "read", "ear", "era"],
  ["rarely", "early", "layer", "relay", "real", "ear", "era"],
  ["really", "early", "layer", "relay", "real", "ear", "era"],
  ["reason", "saner", "snare", "earn", "near", "ear", "era"],
  ["regard", "grade", "dare", "dear", "read", "ear", "era"],
  ["relief", "rifle", "filer", "flier", "file", "life", "lie"],
  ["remain", "marine", "ramen", "earn", "near", "ear", "era"],
];
