import "./App.css";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  Container,
  Letter,
  Top,
  WordWrapper,
  Lives,
  Heart,
  Submit,
  WordButtonWrapper,
  ResultChart,
  Row,
  Label,
  Line,
  Score,
  LineContainer,
  Header,
  Logo,
  Countdown,
  Time,
} from "./app.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import Cookies from "universal-cookie";
import { dictionaryCompiled } from "./dictionaryCompiled";
import Generate from "./Generate";
import moment from "moment";

const generateList = false;
const calculateDuration = () =>
  moment.utc(moment().endOf("day").diff(moment())).format("HH:mm:ss");

function App() {
  const cookies = new Cookies();
  const maxLives = 6;
  const [anagrams, setAnagrams] = useState([]);
  const [scores, setScores] = useState([]);
  const [lives, setLives] = useState(maxLives);
  const [dragging, setDragging] = useState(null);
  const [completeIndex, setCompleteIndex] = useState(0);
  const [completed, setCompleted] = useState([]);
  const [guess, setGuess] = useState({});
  const [locked, setLocked] = useState({});
  const [used, setUsed] = useState({});
  const [untilMidnight, setUntilMidnight] = useState(calculateDuration());
  const timerRef = useRef(0);

  const timerCallback = useCallback(() => {
    setUntilMidnight(calculateDuration());
  }, []);

  useEffect(() => {
    timerRef.current = setInterval(timerCallback, 1);
    return () => {
      clearInterval(timerRef.current);
    };
  }, []);

  const pickWord = () => {
    setGuess({});
    setAnagrams(
      dictionaryCompiled[moment().dayOfYear()].map((w) => w.split(""))
    );
    setCompleteIndex(0);
  };

  useEffect(() => {
    pickWord();
    const scrs = cookies.get("scores");
    if (scrs) {
      setScores(scrs);
      if (moment(scrs[scrs.length - 1].date).isAfter(moment().startOf("day"))) {
        setCompleteIndex(scrs[scrs.length - 1].score);
        setLives(0);
      }
    }
  }, []);

  const handleKeypress = (e) => {
    const toIndex = findEmpty();
    if (/^[a-z]+$/.test(e.key)) {
      const fromIndex = anagrams[completeIndex].findIndex(
        (l, i) => l === e.key && !used[i]
      );
      addLetter(fromIndex, toIndex);
    } else if (
      e.key === "Enter" &&
      Object.values(guess).length === anagrams[completeIndex + 1].length
    ) {
      submitWord();
    } else if (e.key === "Backspace" && toIndex !== undefined && toIndex > 0) {
      removeLetters(toIndex - 1);
    } else if (
      e.key === "Backspace" &&
      anagrams[completeIndex + 1].length === Object.values(guess).length
    ) {
      removeLetters(Object.values(guess).length - 1);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeypress, false);
    return () => {
      document.removeEventListener("keydown", handleKeypress, false);
    };
  }, [handleKeypress]);

  if (generateList) {
    return Generate();
  }

  const addLetter = (fromIndex, toIndex) => {
    const letter = anagrams[completeIndex][fromIndex];
    if (
      Object.values(guess).filter((l) => l === letter).length <
      anagrams[completeIndex].filter((l) => l === letter).length
    ) {
      setGuess({ ...guess, [toIndex]: letter });
      setUsed({ ...used, [fromIndex]: true });
    }
  };

  const removeLetters = (indexes) => {
    const inds = typeof indexes === "number" ? [indexes] : indexes;
    let newUsed = { ...used };
    let newGuess = { ...guess };
    inds.forEach((index) => {
      const letter = guess[index];
      const fromIndex = anagrams[completeIndex].findIndex(
        (l, i) => l === letter && newUsed[i]
      );
      delete newUsed[fromIndex];
      delete newGuess[index];
    });

    setUsed(newUsed);
    setGuess(newGuess);

    return { newGuess, newUsed };
  };

  const submitWord = async () => {
    // first find a match, it might not be the very next work, so look for an incomplete word of the same length
    const match = anagrams.findIndex(
      (a, i) =>
        a.join("") === Object.values(guess).join("") && !completed.includes(i)
    );

    if (match > -1 && match !== completeIndex + 1) {
      [anagrams[match], anagrams[completeIndex + 1]] = [
        anagrams[completeIndex + 1],
        anagrams[match],
      ];
      setAnagrams(anagrams);
    }

    const remove = Object.values(guess)
      .map((letter, i) =>
        letter !== anagrams[completeIndex + 1][i] ? i : null
      )
      .filter((ind) => ind !== null);
    if (remove.length) {
      const now = removeLetters(remove);
      setLocked(now.newGuess);
      if (lives === 1) {
        updateScores(completeIndex, lives - 1);
      }
      setLives(lives - 1);
    } else {
      if (completeIndex === anagrams.length - 2) {
        updateScores(completeIndex + 1, lives);
      }
      setGuess({});
      setUsed({});
      setLocked({});
      setCompleteIndex(completeIndex + 1);
      setCompleted([...completed, completeIndex + 1]);
    }
  };

  const updateScores = async (scoreToAdd, endLives) => {
    let newScores;
    if (!cookies.get("scores") || cookies.get("scores") === "undefined") {
      newScores = [
        { date: moment().toDate(), score: scoreToAdd, lives: endLives },
      ];
    } else {
      newScores = await cookies.get("scores");
      newScores.push({
        date: moment().toDate(),
        score: scoreToAdd,
        lives: endLives,
      });
    }
    setScores(newScores);
    cookies.set("scores", newScores, { path: "/", maxAge: 2147483647 });
  };

  const findEmpty = () => {
    return anagrams[completeIndex + 1].map((l, i) => i).find((i) => !guess[i]);
  };

  const completedTitles = [
    "Yikes!",
    "Eeek...",
    "Nice one",
    "Good work",
    "Excellent!",
    "Brilliant!",
    "Amazing!!",
  ];

  const completedBody = [
    "Afraid you didn't get any words today...",
    "Not great, but at least you got one!",
    "Not bad, you got two words today.",
    "Yeah! You got 3 of them, not bad.",
    "Great work, you managed 4 today :)",
    "Wow, 5 words is great, just one away from perfection!",
    "You got the lot!! Brilliant work!",
  ];

  const ResultsChart = () => {
    let results = [0, 0, 0, 0, 0, 0, 0];
    if (scores) {
      scores.forEach((r) => {
        results[r.score]++;
      });
    }
    const total = results.reduce((a, b) => a + b, 0);
    return (
      <ResultChart>
        {results.map((r, i) => (
          <Row key={i}>
            <Label>{i} words</Label>
            <LineContainer>
              <Line width={(100 / total) * r} todays={i === completeIndex}>
                <Score>{r > 0 && r}</Score>
              </Line>
            </LineContainer>
          </Row>
        ))}
      </ResultChart>
    );
  };
  return (
    <Container>
      <Header>
        <Logo src={require("../src/images/logo_full.png")} />
      </Header>
      <Top>
        <Lives>
          {lives > 0 &&
            completeIndex < anagrams.length - 1 &&
            Array.apply(null, { length: maxLives }).map((e, i) => (
              <Heart key={i} className={i >= lives ? "" : "active"}>
                <FontAwesomeIcon style={{ marginRight: 5 }} icon={faHeart} />
              </Heart>
            ))}
        </Lives>
        {(!lives || completeIndex === anagrams.length - 1) && (
          <>
            <h1 style={{ margin: 0, color: "#d15f5f" }}>
              {completedTitles[completeIndex]}
            </h1>
            <p>{completedBody[completeIndex]}</p>
            <ResultsChart />
            <Countdown>
              Play again in: <Time>{untilMidnight}</Time>
            </Countdown>
          </>
        )}
      </Top>
      {anagrams.map((w, i) => (
        <WordButtonWrapper key={i}>
          <WordWrapper>
            {w.map((l, ii) => (
              <Letter
                className={`lives${lives}`}
                key={ii}
                orinalWord={!i}
                used={used[ii] && completeIndex === i}
                completed={completeIndex >= i}
                droppable={completeIndex === i - 1 && lives > 0}
                draggable={completeIndex === i && lives > 0}
                locked={locked[ii] && completeIndex + 1 === i}
                onDragStart={() => {
                  setDragging(ii);
                }}
                onDrop={(e) => {
                  if (completeIndex === i - 1) {
                    addLetter(dragging, ii);
                  }
                }}
                onDragOver={(event) => {
                  if (completeIndex === i - 1) {
                    event.stopPropagation();
                    event.preventDefault();
                  }
                }}
                onClick={() => {
                  if (!lives) {
                    return false;
                  }
                  if (completeIndex === i) {
                    const emptyI = findEmpty();
                    addLetter(ii, emptyI);
                  } else if (completeIndex === i - 1 && guess[ii]) {
                    removeLetters(ii);
                  }
                }}
              >
                {completeIndex >= i || !lives
                  ? l.toString().toUpperCase()
                  : completeIndex === i - 1 && guess[ii]
                  ? guess[ii].toString().toUpperCase()
                  : null}
              </Letter>
            ))}
          </WordWrapper>
          {completeIndex !== anagrams.length - 1 &&
            Object.values(guess).length ===
              anagrams[completeIndex + 1].length &&
            completeIndex === i - 1 && (
              <Submit onClick={submitWord}>submit</Submit>
            )}
        </WordButtonWrapper>
      ))}
    </Container>
  );
}
export default App;
