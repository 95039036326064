import styled from "styled-components";

export const Heart = styled.span`
  color: #f9f9f9;
  transition: 0.5s;
  &.active {
    color: #ff7171;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
`;

export const WordWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const WordButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const Header = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  border-bottom: #c6c6c6 1px solid;
  max-width: 360px;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
`;

export const Countdown = styled.div`
  margin: 0;
  font-weight: bold;
  // color: #3e1f9b;
`;
export const Time = styled.span`
  color: #589dee;
`;

export const Top = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled.img`
  width: 100%;
`;

export const SubmitWrapper = styled.div`
  max-height: 0;
  overflow: hidden;
`;

export const Submit = styled.button`
  filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.1))
    drop-shadow(-3px -3px 3px rgba(255, 255, 255, 0.8));
  margin: 15px;
  border: none;
  background-color: white;
  padding: 5px 20px;
  border-radius: 15px;
  cursor: pointer;
  transition: 0.2s;
  color: #222;
  &:hover {
    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.1))
      drop-shadow(-1px -1px 1px rgba(255, 255, 255, 0.8));
  }
`;

export const Lives = styled.div`
  font-size: 32px;
  font-weight: bold;
  color: #222;
`;

export const Letter = styled.div`
  margin: 4px;
  background-color: ${({ droppable, completed }) =>
    droppable || completed ? "#fff" : "transparent"};
  border: ${({ completed, droppable, orinalWord, locked }) =>
      orinalWord
        ? "#000"
        : (completed || locked)
        ? "#48c648"
        : droppable
        ? "#bae3c3"
        : "#f9f9f9"}
    2px solid;
  opacity: ${({ used, draggable }) => (used && draggable ? 0.5 : 1)};
  width: 40px;
  height: 40px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  cursor: ${({ draggable }) => (draggable ? "pointer" : "default")};
  border-radius: 22px;
  transition: 0.2s;
  color: #222;
  pointer-events: ${({ used, locked }) =>
    used || locked ? "none" : "default"};
  ${({ draggable }) =>
    draggable &&
    "filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.2)) drop-shadow(-4px -4px 4px rgba(255, 255, 255, 1));"}
  ${({ droppable }) =>
    droppable &&
    "filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.1)) drop-shadow(-2px -2px 2px rgba(255, 255, 255, 0.5));"}
		&.lives0 {
    width: 30px;
    height: 30px;
    font-size: 13px;
    margin: 1px;
    border-width: 1px;
  }
`;

export const ResultChart = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  margin: 20px auto;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 1px 0;
`;

export const Label = styled.div`
  border-right: #bbb 1px solid;
  font-size: 12px;
  margin-right: 5px;
  width: 60px;
`;

export const Score = styled.div`
  font-size: 12px;
  color: #fff;
  text-align: right;
  position: absolute;
  right: 3px;
  height: 100%;
`;

export const LineContainer = styled.div`
  position: relative;
  flex: 1;
`;
export const Line = styled.div`
  background-color: ${({ todays }) => (todays ? "#db44c5" : "#3e1f9b")};
  height: 16px;
  width: ${(props) => props.width}%;
  position: relative;
`;
