import "./App.css";
import { useEffect, useState } from "react";
import { dictionary } from "./dictionary";
function Generate() {
  const [anagrams, setAnagrams] = useState([]);
  const starterLength = 6;
  const fistAnaLength = 6;
  const pyramid = false;
  const minNumber = 6;
  const maxNumber = 6;

  // const [anagramLookup, setAnagramLookup] = useState({});
  const getAnas = () => {
    const dic = [...dictionary].filter((w) => w.length === starterLength);
    const ana = dic.map((w) => findAno(w));
    setAnagrams(ana);
  };

  useEffect(getAnas, []);

  const findAno = (word) => {
    const found = search(fistAnaLength, word, [], word);
    return { word: word.join(""), anagrams: found.map((w) => w.join("")) };
  };

  const search = (length, word, anas, origWord) => {
    const dic = [...dictionary].filter(
      (w) =>
        w.length === length &&
        w.join("") !== origWord.join("") &&
        !anas.includes(w)
    );

    const indexes = dic.reduce(
      (a, w, i) =>
        !w.find(
          (l) =>
            w.filter((ll) => ll === l).length >
            word.filter((ll) => ll === l).length
        )
          ? a.concat(i)
          : a,
      []
    );

    if (indexes.length) {
      const picked = indexes
        .map((ind) => {
          return search(length, dic[ind], [...anas, dic[ind]], origWord);
        })
        .sort((a, b) => b.length - a.length);
      return search(length, picked[0], picked[0], origWord);
    } else if (length > 1) {
      return search(length - 1, word, anas, origWord);
    } else {
      return anas;
    }
  };

  let list = anagrams.sort((a, b) => a.anagrams.length - b.anagrams.length);
  list = list
    .map((w) => ({
      ...w,
      anagrams: w.anagrams.filter(
        (a, i) => !i || !pyramid || a.length < w.anagrams[i - 1].length
      ),
    }))
    .filter((a) => a.anagrams.length >= minNumber)
    .map((w) => [w.word, ...w.anagrams.slice(0, maxNumber)]);

  return JSON.stringify(list.sort((a,b)=>Math.random - Math.random()));
}
export default Generate;
